<template>
  <blog-comp />
</template>

<script>
import BlogComp from "../components/blog/BlogComp.vue";
export default {
  components: { BlogComp },
  name: "blog-page",
};
</script>
